.section-sponsors {
    // padding-bottom: 0;
    background: white;
    // background: white;

    * {
        color: var(--header-bg-color);
    }

    .headingContainer {
        border-radius: 16px;
        background-image: -webkit-linear-gradient(left, #FCEDB7, #FCF9E2, #E0CA98, #FBF1C2);
        padding: 0 5px;

        .headingWrapper {
            background-image: url('../../assets/headingBg.jpg');
            background-size: cover;
            border-radius: 16px;

            .heading {
                text-align: center;
                text-transform: uppercase;
                background: -webkit-linear-gradient(left, #FCEDB7, #FCF9E2, #E0CA98, #FBF1C2);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 5px 0;
                padding: 5px 0;
            }
        }

    }

    .sdcHeadingWrapper {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        width: 100%;
    }

    .sdcHeading {
        // padding-top: 2px;
        width: 75%;
    }

    .content {
        margin-top: 10px;

        p {
            font-family: 'Raleway', sans-serif;
            font-weight: 400;
        }

        h3 {
            text-align: center;
            text-transform: uppercase;
        }

        .tier {
            display: flex;
            flex-direction: column;
            align-items: center;
            // margin-bottom: 20px;

            .companies {
                margin-top: 30px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                flex-wrap: wrap;
                gap: 15px;

                .company {
                    img {
                        // max-width: 200px;
                        width: 150px;
                        height: 150px;
                        object-fit: contain;
                    }
                }

                .full {
                    width: 100%;
                    text-align: center;

                    img {
                        width: 400px;
                    }
                }
            }

        }
    }

    .text-center {
        text-align: center;
        margin-top: 100px;
    }

}

.mediaSponsors {
    margin-top: 50px
}