.section-tickets {
    * {
        color: black;
    }

    .msg {
        // display: flex;
        width: 100%;
        h3 {
            font-size: 20px;
        }
        input {
            font-size: 15px;
        }
    }

    .reminder {
        text-align: center;
        margin-bottom: 20px;
    }

    .ticketCount {
        text-align: center;
        font-weight: 800;
        margin-bottom: 25px;
    }

    #submit {
        span {
            color: white !important;
        }
    }

    #payment-message {
        // display: none;
        text-align: center;
        margin-top: 20px;
        color: red;
    }

    select {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
        background-position: 100%;
        background-repeat: no-repeat;
        padding-right: 50px;
    }

    .headingContainer {
        border-radius: 16px;
        background-image: -webkit-linear-gradient(left, #FCEDB7, #FCF9E2, #E0CA98, #FBF1C2);
        padding: 0 5px;

        .headingWrapper {
            background-image: url('../../assets/headingBg.jpg');
            border-radius: 16px;

            .heading {
                text-align: center;
                text-transform: uppercase;
                background: -webkit-linear-gradient(left, #FCEDB7, #FCF9E2, #E0CA98, #FBF1C2);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 5px 0;
                padding: 5px 0;
            }
        }

    }

    .tickets-container {
        .ticket-stub {
            border-bottom: 1px solid lightgrey;

            .ticketDescWrapper {
                padding: 30px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .ticketDesc {
                    flex-basis: 0;
                    flex-grow: 1;
                    margin-right: 10px;
                }

                select {
                    flex-basis: 0;
                    // flex-grow: 1;
                    width: 200px;
                }
            }

            .peopleForm {
                .table {
                    padding: 10px;
                    border: 1px solid lightgrey;
                    border-radius: 16px;
                }

                .person {
                    background: #F5F5F5;
                    padding: 10px;
                    border-radius: 8px;
                    margin: 10px 0;

                    h5 {
                        svg {
                            margin-right: 10px;
                        }

                        margin: 0;
                        margin-bottom: 10px;
                    }
                }

            }
        }
    }

    input::placeholder {
        color: grey !important;
    }

    .donations-container {
        margin-top: 100px;

        .donation {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;

            input {
                margin-top: 20px;
            }

            .donationDesc {
                margin-bottom: 50px;
            }

            .optionRow {
                display: flex;
                width: 100%;
                justify-content: space-evenly;
                flex-wrap: wrap;
            }
        }

        .form-check-inline {
            input {
                width: 20px;
                height: 20px;
                margin: 0;
                margin-right: 10px;
            }

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .seating-container {
        margin-top: 100px;

        textarea {
            margin-top: 20px;
            height: 200px;
            padding: 10px 15px;
        }
    }

    .contact-container {
        margin-top: 100px;

        .purchaser {
            margin-top: 50px;

            input {
                transition: border 0.25s;
                outline: none;
            }

            textarea {
                height: 200px;
            }

            .contactInvalid {
                border: 1px solid red;
            }
        }
    }

    .payment-container {
        margin-top: 100px;

        .paymentDesc {
            h4 {
                text-transform: uppercase;
            }

            .totalAmount {
                color: #44c767;
            }
        }

        button {
            width: 100%;
            height: 50px;
            border: none;
            outline: none;
            border-radius: 16px;
            color: white;
            background: #0c53a0;
            margin-top: 25px;
            // text-transform: uppercase;
            cursor: pointer;
            transition: all 0.25s;
        }

        button:hover {
            opacity: 0.8;
        }

        h6 {
            width: 100%;
            text-align: center;
        }
    }
}

.messageContainer {
    padding: 130px 0 !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    p {
        color: black;
        font-size: 30px;
    }
}