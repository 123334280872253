.section-auction-preview {
    .image-over {
        display: flex;
        align-items: center;
        justify-content: center;

        .card-img-top {
            height: 200px;
            width: 200px;
            object-fit: contain;
        }
    }

    .swiper-slide {
        height: 350px;
        display: flex;

        .card {
            flex-grow: 1;
            margin: 18px;
            display: flex;

            .card-caption {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }

    .card-caption {
        flex: none;

        h6 {
            font-size: 15px;
            text-align: center;
        }
    }

    .card-bottom:first-of-type {
        margin-top: 10px;
    }

    .card-bottom {
        span {
            color: black !important;
            font-size: 12px;
        }
    }
}